import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { getContactRequests, IContactRequest } from '@services/contactservice'

import LoadingSkeleton from '@objects/loadingSkeleton'
import WarningNote from '@objects/warningnote'

import ContactCard from '@objects/cards/contact'

export default function ContactRequestPage() {
  const intl = useIntl()
  const [contactRequests, setContactRequests] =
    useState<Array<IContactRequest>>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    updateContactRequestList()
  }, [])

  function onActionClick() {
    updateContactRequestList()
  }

  function updateContactRequestList() {
    setLoading(true)
    getContactRequests().then((request) => {
      setContactRequests(request)
      setLoading(false)
    })
  }

  function renderCards() {
    if (!contactRequests?.length) return

    return contactRequests.map((request) => (
      <ContactCard
        key={request.id}
        request={request}
        onActionClick={onActionClick}
      />
    ))
  }
  return (
    <>
      <WarningNote
        text={intl.formatMessage({
          id: 'page.gdpr.note',
        })}
      />
      <LoadingSkeleton loading={loading} type="card" number={4}>
        {renderCards()}
      </LoadingSkeleton>
    </>
  )
}
